import React, {useState} from 'react';
import './App.scss';
import './socket.js'; // Initialise connection for messages from lambdas
import {Navigate, Route, Routes} from 'react-router-dom';
import {AdCreatorAuto} from './pages/Ad-creatorAutoPage/AdCreatorAuto.page.jsx';
import ViewCampaignsPage from './pages/viewCampaignsPage/viewCampaigns.page.jsx';
import Header from './Components/ui/Header/header.component.jsx';
import LogOutPopUp from './Components/ui/logoutPopUp/logOutPopup.component.jsx';
import VerifyAuth from './utils/verifyAuth.js';
import ErrorBoundary from './Components/ui/errorBoundary/errorBoundary.component.jsx';
//view-campaigns
function App() {
  const [logOutPopUp, setLogOutPopUp] = useState(false);
  return (
    <>
      <div className="App">
        <ErrorBoundary>
          <Header />
          <header className="App-header">
            <LogOutPopUp message="You're about to be signed out." subMessage="Your login session has expired, please refresh to login again" toggle={logOutPopUp} setToggle={setLogOutPopUp} />
            <Routes>
              <Route element={<VerifyAuth setLogOutPopUp={setLogOutPopUp} />}>
                <Route path="/" element={<AdCreatorAuto setLogOutPopUp={setLogOutPopUp} />} />
                <Route path="/view-campaigns" element={<ViewCampaignsPage setLogOutPopUp={setLogOutPopUp} />} />
                <Route path="/callback" element={<Navigate to="/" replace />} />
                <Route path="*" element={<Navigate to="/" replace />} />
              </Route>
            </Routes>
          </header>
        </ErrorBoundary>
      </div>
    </>
  );
}
export default App;
