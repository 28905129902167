const loadFonts = (fonts) => {
  if (!fonts || fonts.length === 0) {
    return;
  }

  const existingLink = document.getElementById('dynamic-google-fonts');

  const fontFamilies = fonts.map((font) => `family=${font.replace(/ /g, '+')}:wght@400;700`).join('&');

  const href = `https://fonts.googleapis.com/css2?${fontFamilies}&display=swap`;

  if (existingLink) {
    existingLink.href = href;
  } else {
    const link = document.createElement('link');
    link.id = 'dynamic-google-fonts';
    link.href = href;
    link.rel = 'stylesheet';
    document.head.appendChild(link);
  }
};

export default loadFonts;
