//React imports
import React from 'react';
import './buttonComponents.scss';
//components and utility functions imports
import {CustomButtonContainer} from './button.styles';
//packages and library imports
import PropTypes from 'prop-types';

const CustomButton = ({children, ...props}) => {
  return (
    <div>
      <CustomButtonContainer {...props}>{children || ''}</CustomButtonContainer>
    </div>
  );
};

CustomButton.propTypes = {
  children: PropTypes.node,
};

export {CustomButton};
