import './header.styles.scss';
import React from 'react';
import {useAuth0} from '@auth0/auth0-react';
import {CustomButton} from '../../form/Button/buttonComponents.component';
import {Link} from 'react-router-dom';
import Cookies from 'js-cookie';
import Loader from '../Loader/loader.component';
const Header = () => {
  const {isLoading, error, isAuthenticated, logout} = useAuth0();

  const logOut = () => {
    //clear all cookies
    const allCookies = Cookies.get();
    // Loop through all cookies and remove each one
    Object.keys(allCookies).forEach((cookieName) => Cookies.remove(cookieName));
    localStorage.clear();
    return logout({logoutParams: {returnTo: window.location.origin}});
  };
  return (
    <>
      {!isLoading && !error ? (
        isAuthenticated ? (
          <div className="header-container">
            <div className="header">
              <Link className="logo-container" to="/">
                <img src="https://d3tzjb2h2cowet.cloudfront.net/mf_logo.png" alt="MediaFerry-Logo" />
              </Link>
              <div className="options">
                <Link className="option" to="/">
                  Home
                </Link>
                <Link className="option" to="/view-campaigns">
                  View-Campaigns
                </Link>
                <CustomButton style={{float: 'right', margin: '20px'}} onClick={() => logOut()}>
                  LogOut
                </CustomButton>
              </div>
            </div>
          </div>
        ) : (
          ''
        )
      ) : (
        <Loader />
      )}
    </>
  );
};

// const LoginOutButton = () => {
//   const {isLoading, error, isAuthenticated, logout} = useAuth0();

//   const logOut = () => {
//     //clear all cookies
//     const allCookies = Cookies.get();
//     // Loop through all cookies and remove each one
//     Object.keys(allCookies).forEach((cookieName) => Cookies.remove(cookieName));
//     localStorage.clear();
//     return logout({logoutParams: {returnTo: window.location.origin}});
//   };
//   //const token = localStorage.getItem('token');

//   return (
//     <>
//       {!isLoading && !error ? (
//         isAuthenticated ? (
//           <CustomButton style={{float: 'right', margin: '20px'}} onClick={() => logOut()}>
//             LogOut
//           </CustomButton>
//         ) : (
//           ''
//         )
//       ) : (
//         <div className="spinner-container ">
//           <div className=" col">
//             <div className="loader" id="loader-6">
//               <span></span>
//               <span></span>
//               <span></span>
//               <span></span>
//             </div>
//           </div>
//         </div>
//       )}
//     </>
//   );
// };
// (
//   <li>
//     <a onClick={loginWithRedirect}>Login</a>
//   </li>
// )

export default Header;
