import React, {useEffect} from 'react';
import {Outlet} from 'react-router-dom';
import {useAuth0} from '@auth0/auth0-react';
import PropTypes from 'prop-types';

const VerifyAuth = ({setLogOutPopUp}) => {
  const {loginWithRedirect, isAuthenticated, getAccessTokenSilently, getIdTokenClaims, isLoading} = useAuth0();

  const setLoginTimeoutAndClearStorage = (tokenExpiration) => {
    const interval = setInterval(() => {
      if (new Date().getTime() > tokenExpiration) {
        console.log('Token expired. Showing logout popup.');
        clearInterval(interval); // Clear the interval once the token has expired
        // localStorage.clear();
        setLogOutPopUp(true);
      }
    }, 60000); // Check every minute
  };

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        const id_token = localStorage.getItem('id_token');
        if (!id_token) {
          setLogOutPopUp(true);
        }
        const tokenExpiration = localStorage.getItem('token_expiration');
        if (tokenExpiration && new Date().getTime() > +tokenExpiration) {
          console.log('Token expired due to inactivity. Showing logout popup.');
          // localStorage.clear();
          setLogOutPopUp(true);
        }
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [setLogOutPopUp]);

  useEffect(() => {
    const handleAuthentication = async () => {
      if (!isAuthenticated && !isLoading) {
        localStorage.clear();
        loginWithRedirect();
      } else if (isAuthenticated) {
        try {
          const prevIdToken = localStorage.getItem('id_token');
          if (prevIdToken) {
            const localStorageExpiration = localStorage.getItem('token_expiration');
            console.log('Token Expiration::', new Date(+localStorageExpiration)); // Debugging purposes
            // Set a timeout to show the logout popup when token expires
            setLoginTimeoutAndClearStorage(localStorageExpiration);
            return console.log('TOKEN DIDNT CHANGE:::', prevIdToken);
          }
          const access_token = await getAccessTokenSilently();
          const idToken = await getIdTokenClaims();
          const tokenExpiration = idToken.exp * 1000; // Token expiration in milliseconds
          console.log('Access Token:', access_token); // Debugging purposes
          console.log('ID Token:', idToken.__raw); // Debugging purposes
          console.log('Token Expiration:', new Date(tokenExpiration)); // Debugging purposes

          localStorage.setItem('access_token', access_token);
          localStorage.setItem('id_token', idToken.__raw);
          localStorage.setItem('token_expiration', tokenExpiration);

          // Set a timeout to show the logout popup when token expires
          setLoginTimeoutAndClearStorage(tokenExpiration);
        } catch (error) {
          console.error('Error fetching tokens:', error);
          setLogOutPopUp(true);
        }
      }
    };

    handleAuthentication();
  }, [isAuthenticated, isLoading, loginWithRedirect, getAccessTokenSilently, getIdTokenClaims, setLogOutPopUp]);

  return isAuthenticated ? <Outlet /> : null;
};

VerifyAuth.propTypes = {
  setLogOutPopUp: PropTypes.func.isRequired,
};

export default VerifyAuth;
