const createQueryString = (queryname, data) => {
  const jsonStr = JSON.stringify(data);
  return `${queryname}=${encodeURIComponent(jsonStr)}`;
};
const parseQueryString = (parameterName) => {
  // Return the value of the URL query-parameter called 'parameterName'.
  const params = new URLSearchParams(window.location.search);
  const data = params.get(parameterName);
  console.log('DATA:::::', data);
  if (data) {
    try {
      const parsed = JSON.parse(data);
      console.log('HERES THE PARSED @@@@@', parsed);
      return parsed;
    } catch (error) {
      console.log(`bad json: ${data}`);
    }
  }
  // If there is no data, or unparseable data,it will return null.
  return null;
};

const extractPidFromUrl = (url) => {
  const searchParams = new URLSearchParams(new URL(url).search);
  const pid = searchParams.get('pid');
  return pid;
};
export {createQueryString, parseQueryString, extractPidFromUrl};
