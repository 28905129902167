//React imports
import React from 'react';
import './campaignsGrid.styles.scss';
//Component and utility function imports
import {CustomButton} from '../../form/Button/buttonComponents.component';
//Packages and library imports
import PropTypes from 'prop-types';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Textarea from '@mui/joy/Textarea';

const CampaignsGrid = ({campaignNames, formats, createdAds, actions, setActions, responses, invokeErrorMessage, editableFields, setEditableFields, refreshIframeKeysFunction}) => {
  // console.log('Table component editableFields', editableFields);
  const updateAction = (colIndex, rowIndex, selectedValue) => {
    const updatedActions = Array.from(actions, (column) => Array.from(column, (entry) => ({action: entry.action})));

    console.log(`Setting updatedActions[${colIndex}][${rowIndex}] to ${selectedValue}`);
    updatedActions[colIndex][rowIndex].action = selectedValue;
    console.log(`TableComponent:updating actions to`, actions);
    setActions((_prevActions) => updatedActions);
  };

  const selectFunction = (e, selectedValue, colIndex, rowIndex) => {
    e.preventDefault();
    //setting response
    //clearing response on the action button, if the button is clicked to be changed
    if (responses[colIndex]) {
      responses[colIndex][rowIndex] = undefined;
    }
    updateAction(colIndex, rowIndex, selectedValue);
  };

  //FORMAT STRING CODE

  const formatJSONString = (createdAd) => {
    // createdAd.url = createdAd.url ? createdAd.url.split('?')[0] : createdAd.url;
    return JSON.stringify(createdAd, null, 2)
      .replace(/\\n/g, '\n') // Replaces escaped newlines with actual newlines if necessary
      .replace(/(\n *)"Error: /, `$1'Error: `) // Fix just 1 error report ins stack backtrace
      .replace(/(\n *)" {4}at /g, `$1'   at `) // Fix remainder of stack trace
      .replace(/(\n *)"/g, '$1') // Replace initial " before property name
      .replace(/":/g, ':') // replace " immediately followed by :
      .replace(/^\s*[\r\n]/gm, '') // Removes empty lines at the start of the string
      .replace(/(\\")|"/g, (match, p1) => (p1 ? match : `'`)) // makes " but not \" into '
      .replace(/\\"/g, '"') // Unescapes double quotes if necessary
      .trim(); // Trims any remaining whitespace from the beginning and end of the string
  };

  const hasNoTemplate = (createdAd) => !createdAd?.template?._id && !createdAd?.obscureString && !createdAd?.url;

  const formatString = (createdAd) => {
    if (!createdAd) {
      return 'No template.';
    }
    if (!(createdAd?.status && createdAd?.action && createdAd?.createdAd) && hasNoTemplate(createdAd)) {
      return 'No template';
    }

    return formatJSONString(createdAd);
  };

  const postfieldsUpdate = async (body, campaignNumber, deliverableNumber) => {
    try {
      const url = 'https://atkjxngxu7.execute-api.us-east-1.amazonaws.com/dev/fields/update';
      const bodyObject = JSON.stringify(body);
      console.log('UPDATING POST FIELDS', bodyObject);
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: bodyObject,
        mode: 'no-cors',
      });
      console.log('HERE IS THE RESPONSE---', response);
      refreshIframeKeysFunction(campaignNumber, deliverableNumber);
    } catch (err) {
      console.log('NAME:', err.name, 'MESSAGE:', err.message, 'ERROR:', err);
      return invokeErrorMessage('Error in POST request to MFA-READPROJECTS', err.message);
    }
  };

  const displayText = (id, obj) => {
    switch (true) {
      case id?.includes('sd_txt'):
        return obj?.text;
      case id?.includes('sd_btn_Click-Through-URL_cta'):
        return obj?.text;
      case id?.includes('sd_btn_Click-Through-URL_href'):
        return obj?.href;
      case id?.includes('sd_bgcolor'):
        return obj?.text;
      case id?.includes('sd_img'):
        return obj?.imageUrl;

      default:
        return '';
    }
  };

  const returnKeyProperty = (id) => {
    switch (true) {
      case id?.includes('sd_txt'):
        return 'text';

      case id?.includes('sd_btn_Click-Through-URL_href'):
        return 'href';

      case id?.includes('sd_btn_Click-Through-URL_cta'):
        return 'text';

      case id?.includes('sd_bgcolor'):
        return 'text';

      case id?.includes('sd_img'):
        return 'imageUrl';

      default:
        return '';
    }
  };

  const isChecked = (id, obj) => {
    return displayText(id, obj);
  };

  const handleFieldChange = (e, id, index, campaignNumber, deliverableNumber) => {
    const key = returnKeyProperty(id);

    setEditableFields((prev) => {
      const newFields = [...prev];
      const campaignFields = [...newFields[campaignNumber]];
      const deliverableFields = campaignFields?.[deliverableNumber]?.fieldInfos;

      deliverableFields[index] = {
        ...deliverableFields?.[index],
        [key]: e?.target?.value,
      };

      newFields[campaignNumber] = campaignFields;
      return newFields;
    });
  };

  const handleCheckboxChange = (id, index, campaignNumber, deliverableNumber) => {
    handleFieldChange({target: {value: ''}}, id, index, campaignNumber, deliverableNumber);
  };

  const autoSubmitPostFieldsList = (campaignNumber, deliverableNumber) => {
    const obscureString = createdAds?.[campaignNumber]?.[deliverableNumber]?.obscureString;
    const updatedEditableFields = editableFields?.[campaignNumber]?.[deliverableNumber]?.fieldInfos.map((fi) => {
      return {...fi, obscureString};
    });
    const body = {fieldUpdates: updatedEditableFields};

    postfieldsUpdate(body, campaignNumber, deliverableNumber);
  };

  return (
    <div className={'campaign-grid-container'}>
      <table>
        <thead>
          <tr>
            {/* Empty cell for the top-left corner */}
            <th></th>
            {/* Column headings */}
            {campaignNames.map((name, index) => (
              <th key={index} className="grid-headers">
                {index}
                <br />
                {(name ?? '').replaceAll('/', '/ ')}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {formats.map((deliverable, rowIndex) => (
            <tr className={'tbody-tr-grid'} key={rowIndex}>
              {/* Row heading */}
              <td className={'tbody-td-grid'}>
                <b>{rowIndex}</b>.{deliverable ? deliverable.replace(deliverable[0], deliverable[0].toUpperCase()) : ''}
              </td>
              {/* Data cells */}
              {campaignNames.map((_, colIndex) => (
                <td key={colIndex}>
                  {/* Add margin for space */}
                  {rowIndex === 0 && createdAds?.[colIndex]?.[rowIndex]?.url && editableFields?.[colIndex]?.[rowIndex]?.fieldInfos && (
                    <>
                      <div>
                        <div className="con-tooltip2">
                          <div className="tooltip2">
                            <section>
                              <pre className="flex-style-column">
                                {editableFields?.[colIndex]?.[rowIndex]?.fieldInfos.map((fi, i) => {
                                  const uniqueKey = `fieldInfo-${colIndex}-${rowIndex}-${i}`;
                                  return !fi?.id?.includes('sd_btn_') ? (
                                    <React.Fragment key={uniqueKey}>
                                      <div className="edit-box">
                                        <FormControl>
                                          <FormLabel>{fi.id}</FormLabel>
                                          <Textarea
                                            style={{marginBottom: '10px', padding: '15px', border: '1px solid black'}}
                                            value={displayText(fi?.id, fi)}
                                            maxRows={2}
                                            id="outlined-basic"
                                            size="small"
                                            variant="outlined"
                                            onChange={(e) => handleFieldChange(e, fi.id, i, colIndex, rowIndex)}
                                          />
                                        </FormControl>
                                        <input checked={isChecked(fi?.id, fi)} onChange={() => handleCheckboxChange(fi.id, i, colIndex, rowIndex)} className="checkButton" type="checkbox" />
                                      </div>
                                    </React.Fragment>
                                  ) : (
                                    <div key={i} className="sd_btn">
                                      <div className="edit-box">
                                        <FormControl>
                                          <FormLabel>{`${fi.id}_cta`}</FormLabel>
                                          <Textarea
                                            style={{marginBottom: '10px', padding: '15px', border: '1px solid black'}}
                                            maxRows={2}
                                            value={displayText(`${fi?.id}_cta`, fi)}
                                            id="outlined-basic"
                                            size="small"
                                            variant="outlined"
                                            onChange={(e) => handleFieldChange(e, `${fi?.id}_cta`, i, colIndex, rowIndex)}
                                          />
                                        </FormControl>
                                        <input
                                          checked={isChecked(`${fi?.id}_cta`, fi)}
                                          onChange={() => handleCheckboxChange(`${fi?.id}_cta`, i, colIndex, rowIndex)}
                                          className="checkButton"
                                          type="checkbox"
                                        />
                                      </div>
                                      <div className="edit-box">
                                        <FormControl>
                                          <FormLabel>{`${fi.id}_href`}</FormLabel>
                                          <Textarea
                                            style={{marginBottom: '10px', padding: '15px', border: '1px solid black'}}
                                            maxRows={2}
                                            value={displayText(`${fi?.id}_href`, fi)}
                                            id="outlined-basic"
                                            size="small"
                                            variant="outlined"
                                            onChange={(e) => handleFieldChange(e, `${fi?.id}_href`, i, colIndex, rowIndex)}
                                          />
                                        </FormControl>
                                        <input
                                          checked={isChecked(`${fi?.id}_href`, fi)}
                                          onChange={() => handleCheckboxChange(`${fi?.id}_href`, i, colIndex, rowIndex)}
                                          className="checkButton"
                                          type="checkbox"
                                        />
                                      </div>
                                    </div>
                                  );
                                })}
                                <CustomButton onClick={() => autoSubmitPostFieldsList(colIndex, rowIndex)} style={{backgroundColor: 'blue'}}>
                                  Update Fields
                                </CustomButton>
                              </pre>
                            </section>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  <div
                    className={`con-tooltip top ${createdAds?.[colIndex]?.[rowIndex]?.template?._id ? '' : 'light-grey'}${createdAds?.[colIndex]?.[rowIndex]?.url ? ' bordered' : ''}${
                      createdAds?.[colIndex]?.[rowIndex]?.template?.adSize ? ' bordered-url' : ''
                    }`}
                  >
                    <div className="tooltip">
                      <section>
                        <pre>
                          {!responses || !responses[colIndex]
                            ? null
                            : responses[colIndex][rowIndex] === undefined
                            ? formatString(createdAds?.[colIndex]?.[rowIndex])
                            : formatString({...responses[colIndex][rowIndex], createdAd: createdAds?.[colIndex]?.[rowIndex]})}
                        </pre>
                      </section>
                    </div>
                    <div>
                      <select value={actions?.[colIndex]?.[rowIndex]?.action ?? ''} onChange={(e) => selectFunction(e, e.target.value, colIndex, rowIndex)}>
                        <option value=""></option>
                        <option value="adaptAd">adaptAd</option>
                        <option value="expandTemplate">expandTemplate</option>
                        <option value="createAd">createAd</option>
                        <option value="listFields">listFields</option>
                        <option value="fillAd">fillAd</option>
                        <option value="makeZipFile">makeZipFile</option>
                        <option value="deleteAd">deleteAd</option>
                        <option value="unzipAd">unzipAd</option>
                        <option value="findDeliverableTemplate">findDeliverableTemplate</option>
                      </select>
                      {!responses || !responses[colIndex] ? null : responses[colIndex][rowIndex] === undefined ? null : responses[colIndex][rowIndex]?.action?.resultCode === 0 ? '✅' : '❌'}
                    </div>
                  </div>
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

CampaignsGrid.propTypes = {
  campaignNames: PropTypes.arrayOf(PropTypes.string).isRequired,
  formats: PropTypes.arrayOf(PropTypes.string).isRequired,
  colIndex: PropTypes.number,
  rowIndex: PropTypes.number,
  handleFieldChange: PropTypes.func,
  handleCheckboxChange: PropTypes.func,
  displayText: PropTypes.func,
  createdAds: PropTypes.arrayOf(PropTypes.array).isRequired,
  editableFields: PropTypes.arrayOf(PropTypes.array).isRequired,
  actions: PropTypes.arrayOf(PropTypes.array).isRequired,
  setActions: PropTypes.func.isRequired,
  invokeErrorMessage: PropTypes.func,
  setEditableFields: PropTypes.func.isRequired,
  refreshIframeKeysFunction: PropTypes.func,
  responses: PropTypes.arrayOf(PropTypes.array).isRequired,
};

export default CampaignsGrid;
