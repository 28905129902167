import React, {useEffect, useState} from 'react';
import './logOutPopUp.scss'; // Make sure to import the CSS file
import PropTypes from 'prop-types';
import {useAuth0} from '@auth0/auth0-react';
import Cookies from 'js-cookie';
const LogOutPopUp = ({message, subMessage, toggle, setToggle}) => {
  const {logout} = useAuth0();
  const [showPopup, setShowPopup] = useState(toggle || false);

  useEffect(() => {
    setShowPopup(toggle);
  }, [toggle]);

  const onLogout = () => {
    const tokenExpiration = localStorage.getItem('token_expiration');
    const currentTime = new Date().getTime();
    if (currentTime > tokenExpiration) {
      console.log('Logging out due to token expiration');
      const allCookies = Cookies.get();
      // Loop through all cookies and remove each one
      Object.keys(allCookies).forEach((cookieName) => Cookies.remove(cookieName));
      localStorage.clear();
      return logout({returnTo: window.location.origin});
    } else {
      setToggle(false);
    }
  };

  const onClose = () => {
    setShowPopup(false);
    setToggle(false);
  };

  return (
    <>
      {showPopup && (
        <div className="popup-container">
          <div className="popup">
            <button className="close-btn" onClick={onClose}>
              &times;
            </button>
            <h4>{message}</h4>
            <p>{subMessage}</p>
            <button className="refresh-btn" onClick={onLogout}>
              Refresh
            </button>
          </div>
        </div>
      )}
    </>
  );
};

LogOutPopUp.propTypes = {
  message: PropTypes.string,
  subMessage: PropTypes.string,
  toggle: PropTypes.bool,
  setToggle: PropTypes.func,
};

export default LogOutPopUp;
