// This is just a standard way of writing time, used in all our functions.
// It is always in UTC, although it doesn't say so. 🙁
const myCurrentTime = () => {
  return new Date().toISOString().slice(0, 19).replace('T', ' ');
};
const isArrayEmpty = (array) => {
  if (!Array.isArray(array)) {
    throw new Error(`isArrrayEmpty: ${array} is not an array, it has type ${typeof array}`);
  }
  return array.every((innerArray) => innerArray?.length === 0);
};
const areAllEntriesEmpty = (array) => {
  if (!Array.isArray(array)) {
    throw new Error(`areAllEntriesEmpty: ${array} is not an array, it has type ${typeof array}`);
  }
  return array.every((entry) => entry === '');
};

const delay = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export {myCurrentTime, isArrayEmpty, areAllEntriesEmpty, delay};
