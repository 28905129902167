import styled, {css} from 'styled-components';
export const InvertedStyles = css`
  background-color: white;
  color: black;
  border: 1px solid black;

  &:hover {
    background-color: black;
    color: white;
    border: none;
  }
`;

const buttonStyles = css`
  background-color: #ff5725;
  color: white;
  font-size: 17px;
  margin-bottom: 30px;
  font-family: sans-serif;
  font-weight: 100;
  border: none;
  border-radius: 3px;
  transition: all 0.1s ease-out;
  &:hover {
    background-color: #ff4000;
    color: white;
    font-size: 16.8px;
  }
`;

export const downloadZip = css`
  background-color: rgb(142, 34, 142);
  outline: none;
  border: 2px solid rgb(142, 34, 142);
  color: white;
  border-radius: 3px;
  font-size: 18px;
  &:hover {
    background-color: purple;
    outline: none;
    color: white;
    border: 2px solid purple;
    cursor: pointer;
    border-radius: 3px;
  }
`;

const setStyles = (props) => {
  if (props.inverted) {
    return InvertedStyles;
  }
  return props.download ? downloadZip : buttonStyles;
};

export const CustomButtonContainer = styled.button`
  min-width: 165px;
  width: auto;
  height: 50px;
  letter-spacing: 0.5px;
  line-height: 50px;
  padding: 0 30px 0 30px;
  font-size: 12px;
  background-color: orange;
  color: white;
  font-family: 'Open Sans Condensed';
  font-weight: lighter;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;

  &:hover {
    background-color: white;
  }
  ${setStyles}
  /* styles for disabled state */
  &:disabled {
    background-color: grey;
    color: #cccccc;
    cursor: not-allowed;
    border: none;

    /* Disable hover effect for disabled button */
    &:hover {
      background-color: grey;
      color: #cccccc;
      border: none;
    }
  }
`;
