import axios from 'axios';
import {STAGE} from './../socket.js';
const mfaCallApi = async (apiCall, body) => {
  console.log('CALL FROM MFA CALL API');
  const id_token = localStorage.getItem('id_token');
  try {
    let url;
    switch (apiCall) {
      // These apicalls issue a POST request to AWS API Gateway which triggers the mfa-stepstart Lambda.
      case 'chooseTemplateCampaigns':
      case 'execute':
      case 'readProject':
        url = `https://pqlogubentwukzzqrwvkmcjsra0aphpq.lambda-url.eu-west-1.on.aws/${STAGE}/mfa/${apiCall}`;
        break;
      default: {
        const s = `ERROR: mfaUrl: unrecognized apiCall: ${apiCall}`;
        console.log(s);
        throw new Error(s);
      }
    }
    console.log(`mfaCall:${apiCall}: sending body:`, body);
    const response = await axios.post(
      url,
      {...body},
      {
        headers: {
          Authorization: `Bearer ${id_token}`,
          'Content-Type': 'application/json',
        },
      }
    );

    return response; // axios wraps the response, so we return response.data
  } catch (err) {
    console.log('NAME:', err.name, 'MESSAGE:', err.message, 'ERROR:', err);
    throw err; // Rethrow the error if needed
  }
};

export default mfaCallApi;
