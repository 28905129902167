import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App.js';
import reportWebVitals from './reportWebVitals.js';
import {BrowserRouter} from 'react-router-dom';
import {AUTH_CREDENTIALS} from './utils/authcredentials.js';
import {Auth0Provider} from '@auth0/auth0-react';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Auth0Provider
      domain={AUTH_CREDENTIALS.domain}
      clientId={AUTH_CREDENTIALS.clientId}
      authorizationParams={{redirect_uri: `${AUTH_CREDENTIALS.callbackUrl}`}}
      audience={AUTH_CREDENTIALS.audience}
      responseType="token id_token"
      scope="openid profile email"
    >
      <App />
    </Auth0Provider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
