//React imports
import React, {useEffect, useState} from 'react';
import './adDiv.styles.scss';

//library and package imports
import PropTypes from 'prop-types';
import {v4 as uuidv4} from 'uuid';
//?buster=${uuidv4()}
const AdDiv = ({createdAd}) => {
  const [iframeSrc, setIframeSrc] = useState('');
  useEffect(() => {
    if (createdAd && createdAd.url) {
      const newUrl = `${createdAd.url}?buster=${uuidv4()}`;
      setIframeSrc(newUrl);
    }
  }, [createdAd]); // Dependency array includes createdAd to ensure refresh happens when ad changes

  const adSize = createdAd?.template?.adSize;
  if (createdAd && adSize) {
    if (typeof createdAd === 'object') {
      const {url} = createdAd;

      if (url) {
        return (
          <div className="container">
            <iframe {...adSize} frameBorder="0" src={iframeSrc} />;
          </div>
        );
      }
    }
  }

  // `data` is undefined, just show a small iframe with the word "undefined" in it.
  const renderTemplateStatus = () => {
    if (createdAd?.url) {
      if (createdAd?.template?.adSize) {
        return 'Template Has not Been Filled';
      } else {
        return 'Ad has not been created';
      }
    } else {
      return 'Template Has not been expanded';
    }
  };

  const renderContent = () => {
    return createdAd?.template?._id ? <span>{renderTemplateStatus()}</span> : <span>No Template To Display!</span>;
  };

  return <div className="container">{renderContent()}</div>;
};

AdDiv.propTypes = {
  createdAd: PropTypes.object,
};

export default AdDiv;
