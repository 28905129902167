//React imports
import React from 'react';
import './input.styles.scss';
//packages and library imports
import PropTypes from 'prop-types';

const InputComponent = ({label, type, name, value, multiline, rows, className, validationError, errorMessage, helpText, ...props}) => {
  const inputClassName = `form__field ${className || ''} ${multiline ? 'form__field--textarea' : ''}`.trim();

  // Render either an input or a textarea based on the 'multiline' prop
  return (
    <>
      <div className="form__group field">
        {multiline ? (
          <textarea
            {...props}
            title={helpText} // Optional help text shown on hover
            rows={rows || 1} // Default to 1 row if not specified
            className={inputClassName}
            placeholder={name}
            name={name}
            id={name}
            required
          >
            {value}
          </textarea>
        ) : (
          <input value={value} {...props} title={helpText} type={type || 'text'} className={inputClassName} placeholder={name} name={name} id={name} required />
        )}
        <label htmlFor={name} className="form__label">
          {label}
        </label>
        {validationError === true && (
          <label htmlFor={errorMessage} className="form__label__error">
            {errorMessage}
          </label>
        )}
      </div>
    </>
  );
};

InputComponent.propTypes = {
  label: PropTypes.string,
  type: PropTypes.any,
  name: PropTypes.string,
  value: PropTypes.any,
  className: PropTypes.string,
  validationError: PropTypes.bool,
  errorMessage: PropTypes.string,
  multiline: PropTypes.bool,
  rows: PropTypes.number,
  helpText: PropTypes.string,
};

export default InputComponent;
