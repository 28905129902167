// Common code for handling downloading zip files from the server.
// Callable for either a signle zipfile or a zipfile of zipfiles.

import axios from 'axios';

export const handleDownloadClick = async (request, setInhibitZipDownload) => {
  // `request` must include either `sourcePrefix` and `zipFileName`
  // or `sourcePrefixes` and `zipFileNames` and `combinedZipFileName`.
  console.log('request:::', request);
  try {
    const response = await axios.post('https://gisptamkxri63ec2fccrjcfhza0sbmjh.lambda-url.eu-west-1.on.aws/', request, {
      responseType: 'blob', // Important to handle binary data
      mode: 'no-cors',
    });

    // Create a Blob from the response and get url for the blob
    const blob = new Blob([response.data], {type: 'application/octet-stream'});
    const downloadUrl = window.URL.createObjectURL(blob);

    // Create a link, trigger download, and remove the link
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.download = request.zipFileName ?? request.combinedZipFileName; // Set the filename for download
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    console.error('Download failed', error);
  }
  setInhibitZipDownload(false);
};
