import React from 'react';
import './loader.styles.scss';
const Loader = () => {
  return (
    <div className="spinner-container">
      <div className="col">
        <div className="loader" id="loader-id"></div>
      </div>
    </div>
  );
};

export default Loader;
