import React from 'react';
import './AdEditor.styles.scss';
import PropTypes from 'prop-types';

export const AdEditor = ({/*formData, setFormData,*/ setEditingCreatedAd, /* createdAd,*/ editableFields, campaignNumber, deliverableNumber}) => {
  const myEditableFields = editableFields?.[campaignNumber]?.[deliverableNumber];
  console.log(`campaignNumber:${campaignNumber},deliverableNumber:${deliverableNumber}, myEditableFields:`, myEditableFields);
  console.log(`editableFields:`, editableFields);
  return (
    <div className="AdEditor">
      {myEditableFields?.adSize ? <>{JSON.stringify(myEditableFields)}</> : <p>Waiting for editable Fields</p>}
      <button onClick={() => setEditingCreatedAd(null)}>Cancel</button>
    </div>
  );
};

AdEditor.propTypes = {
  formData: PropTypes.object,
  setFormData: PropTypes.func,
  setEditingCreatedAd: PropTypes.func,
  createdAd: PropTypes.object,
  editableFields: PropTypes.array,
  campaignNumber: PropTypes.number,
  deliverableNumber: PropTypes.number,
};
