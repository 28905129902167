import React, {useEffect, useState} from 'react';
import './viewCampaigns.styles.scss';
import PropTypes from 'prop-types';
import axios from 'axios';
import Pagination from '@mui/material/Pagination';
import Loader from '../../Components/ui/Loader/loader.component';
import {createQueryString} from '../../utils/urlUtils';

const pageInterval = 20;

const ViewCampaignsPage = ({setLogOutPopUp}) => {
  const [allUsersProject, setAllUsersProject] = useState([]);
  const [loading, setLoading] = useState(true);
  const [numberOfPages, setNumberOfPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [paginatedUserProject, setPaginatedUserProject] = useState([]);

  useEffect(() => {
    const getAllCampaigns = async () => {
      const userToken = localStorage.getItem('id_token');
      const tokenExpiration = new Date(localStorage.getItem('token_expiration'));

      if (!userToken || new Date() > tokenExpiration) {
        setLogOutPopUp(true);
        return;
      }

      try {
        const response = await axios.get('https://42xb42t4vdo2efmp3emzwlaofm0vphmy.lambda-url.eu-west-1.on.aws/mediaferry/getAllProjects', {
          headers: {Authorization: userToken},
        });

        if (response.status === 200) {
          const campaigns = response.data || [];
          const sortedProjects = campaigns.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

          setAllUsersProject(sortedProjects);
          setNumberOfPages(Math.ceil(campaigns.length / pageInterval));
          getProjectsForCurrentPage(1, sortedProjects);
        } else {
          throw new Error('Failed to fetch campaigns');
        }
      } catch (error) {
        console.error('Error fetching campaigns:', error);
        setAllUsersProject([]);
      } finally {
        setLoading(false);
      }
    };

    getAllCampaigns();
  }, [setLogOutPopUp]);

  const getProjectsForCurrentPage = (page, projectsArray) => {
    const start = (page - 1) * pageInterval;
    const end = start + pageInterval;
    setPaginatedUserProject(projectsArray.slice(start, end));
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
    getProjectsForCurrentPage(value, allUsersProject);
  };

  const openNewTab = (queryString) => {
    const query = queryString ? `?${queryString}` : '';
    const newTabURL = `${window.location.origin}/${query}`;
    window.open(newTabURL, '_blank');
  };

  const formatDate = (date) => {
    const options = {day: '2-digit', month: '2-digit', year: 'numeric'};
    return new Date(date).toLocaleDateString('en-GB', options);
  };

  const latestCampaigns = allUsersProject.slice(0, 2);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="campaigns-page">
          <h1 className="view-campaigns-header">Your Campaigns</h1>
          {allUsersProject.length === 0 ? (
            <div className="no-campaigns">
              <p>You have no created campaigns</p>
              <div onClick={() => openNewTab('')} className="new-campaign-card">
                <div className="new-campaign-content">
                  <div className="plus-icon">+</div>
                  <p>New Campaign</p>
                </div>
              </div>
            </div>
          ) : (
            <div className="campaigns-container">
              <div className="latest-campaigns">
                {latestCampaigns.map((campaign) => (
                  <div
                    key={campaign.projectId}
                    onClick={() => {
                      const queryString = `${createQueryString('projectId', campaign.projectId)}&${createQueryString('tab', '2')}&${createQueryString('createdAd', 'yes')}`;
                      openNewTab(queryString);
                    }}
                    className="campaign-card"
                  >
                    <img
                      src="https://d3tzjb2h2cowet.cloudfront.net/mf_logo.png" // Replace with actual image URL
                      alt={campaign.projectName}
                      className="campaign-image"
                    />
                    <div className="campaign-info">
                      <h3>{campaign.projectName || 'No Name'}</h3>
                      <p>Created on {formatDate(campaign.createdAt)}</p>
                    </div>
                  </div>
                ))}
                <div onClick={() => openNewTab('')} className="new-campaign-card">
                  <div className="new-campaign-content">
                    <div className="plus-icon">+</div>
                    <p>New Campaign</p>
                  </div>
                </div>
              </div>
              {paginatedUserProject.length > 0 && (
                <>
                  <div className="all-campaigns-table">
                    <table>
                      <thead>
                        <tr>
                          <th>Campaign Name</th>
                          <th>Created on</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {paginatedUserProject.map((campaign) => (
                          <tr key={campaign.projectId}>
                            <td>{campaign.projectName || 'No Name'}</td>
                            <td>{formatDate(campaign.createdAt)}</td>
                            <td
                              onClick={() => {
                                const queryString = `${createQueryString('projectId', campaign.projectId)}&${createQueryString('tab', '2')}&${createQueryString('createdAd', 'yes')}`;
                                openNewTab(queryString);
                              }}
                              className="actions"
                            >
                              &#128065; Preview
                            </td>
                            <td
                              onClick={() => {
                                const queryString = `${createQueryString('projectId', campaign.projectId)}&${createQueryString('tab', '2')}&${createQueryString(
                                  'createdAd',
                                  'yes'
                                )}&${createQueryString('newProject', 'yes')}`;
                                openNewTab(queryString);
                              }}
                              className="actions"
                            >
                              <b>&#x2398;</b> Duplicate
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <div className="pagination-container">
                    <Pagination count={numberOfPages} variant="outlined" shape="rounded" page={currentPage} onChange={handlePageChange} />
                  </div>
                </>
              )}
            </div>
          )}
        </div>
      )}
    </>
  );
};

ViewCampaignsPage.propTypes = {
  setLogOutPopUp: PropTypes.func.isRequired,
};

export default ViewCampaignsPage;
